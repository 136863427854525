import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { HelpfulLinks } from 'components/HelpContainer';

import './NotFoundContainer.scss';

const NotFoundContainer = ({ data }) => (
  <div className="aaa-notfound">
    <ContentBox>
      <HelpfulLinks data={data.helpfulLinks} />
    </ContentBox>
  </div>
);

export default NotFoundContainer;
